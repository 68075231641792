import { Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'
import {
  FindFleetOrdersData,
  FindFleetOrdersResponse,
  FindByIdResponse,
  GetServiceLocationDetailsResponse,
  GetCsvDataOrSendViaEmailData,
  CsvResponse,
} from '../models/fleet-order.models'
import { Observable } from 'rxjs'
import { pluck } from 'rxjs/operators'
import { setHttpParams } from './helpers/set-http-params.helper'

@Injectable()
export class FleetOrderApi {
  constructor(private http: HttpClient) {}

  // post method due to get param limits
  public find(data: FindFleetOrdersData): Observable<FindFleetOrdersResponse> {
    return this.http.post<FindFleetOrdersResponse>('/FleetOrder', {
      ...data,
      fleetAccountIds:
        data.fleetAccountIds !== 'ALL' ? data.fleetAccountIds : [],
      subAccountIds: data.subAccountIds !== 'ALL' ? data.subAccountIds : [],
      from: data.from ? Number.parseInt(data.from) : undefined,
      to: data.to ? Number.parseInt(data.to) : undefined,
    })
  }

  public getCsvDataOrSendViaEmail(
    data: GetCsvDataOrSendViaEmailData
  ): Observable<CsvResponse> {
    return this.http.post<CsvResponse>('/FleetOrder/getCsvDataOrSendViaEmail', {
      ...data,
      fleetAccountIds:
        data.fleetAccountIds !== 'ALL' ? data.fleetAccountIds : [],
      subAccountIds: data.subAccountIds !== 'ALL' ? data.subAccountIds : [],
      from: data.from ? Number.parseInt(data.from) : undefined,
      to: data.to ? Number.parseInt(data.to) : undefined,
    })
  }

  public getCsvDownloadUrl(data: {
    fleetCompanyId: string
    objectName: string
  }): Observable<string> {
    return this.http
      .get<{ downloadUrl: string }>('/FleetOrder/getCsvDownloadUrl', {
        params: setHttpParams(data),
      })
      .pipe(pluck('downloadUrl'))
  }

  public findById(fleetOrderId: string): Observable<FindByIdResponse> {
    return this.http.get<FindByIdResponse>(`/FleetOrder/${fleetOrderId}`)
  }

  public getServiceLocationDetails(
    fleetOrderId: string
  ): Observable<GetServiceLocationDetailsResponse> {
    let params = new HttpParams()
    params = params.append('fleetOrderId', fleetOrderId)
    return this.http.get<GetServiceLocationDetailsResponse>(
      '/FleetOrder/getLocationDetails',
      { params }
    )
  }
}
